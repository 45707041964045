import React from "react";
import scroll from "./assets/photos/scroll.png";
import bk from "./assets/photos/bk1.png";
import bk2 from "./assets/photos/bk2.png";
import bk3 from "./assets/photos/bk3.png";
import preview from "./assets/photos/preview.png";
import preview2 from "./assets/photos/screenshot1.png";
import preview3 from "./assets/photos/screenshot2.png";
import preview4 from "./assets/photos/screenshot3.png";
import preview5 from "./assets/photos/screenshot5.png";
import discord from "./assets/photos/discord-mark-blue.png";
import logo from "./assets/photos/aside-logo.png";
import solana from "./assets/photos/Solana_logo.png";
import ParallaxSection from "./parallaxSection";

export default function LandingPage() {
    return (
        <div>
            <div className="h-screen w-[100vw] overflow-x-hidden snap-y snap-mandatory">
                <ParallaxSection className="h-screen snap-start snap-always" bgImage={bk}>
                <img src={logo} alt="Logo" className="w-full px-16 pt-12 sm:p-0 sm:w-60 sm:h-10 absolute sm:left-16 sm:top-8 z-20" />
                    <div className="flex sm:flex-row flex-col h-screen items-center justify-center sm:gap-x-12 gap-y-4 p-4">
                        <div className="sm:w-2/5 flex flex-col sm:gap-y-8 gap-y-2 items-center sm:items-start">
                            <h2 className="text-white textShadow text-3xl text-center sm:text-left sm:text-4xl 2xl:text-6xl font-bold font-sans">Create Non-Custodial Index Funds with Portfolio Copy Trading</h2>
                            <h3 className="text-white drop-shadow-md text-center sm:text-left sm:text-3xl text-xl">A simple way to diversify your De-Fi assets.<br /><span className="flex flex-row items-center justify-center sm:justify-start gap-x-2 text-center sm:text-left sm:text-3xl">Powered by Solana <img src={solana} alt="solana logo" style={{ height: "32px", width: "32px", verticalAlign: "middle" }}></img></span></h3>
                            <div className="flex flex-row w-2/3 justify-center items-center gap-x-4 py-4">
                                <a href="https://discord.com/invite/NUyrtbv9jR" target="_blank" className="h-full p-4 text-[#5865F2] bg-white rounded-full opacity-80 hover:text-[#7681fe] hover:bg-gray-300 w-1/2 font-bold sm:text-2xl text-l" disabled rel="noreferrer"><span className="flex flex-row items-center justify-center gap-x-2 h-full" ><img src={discord} alt="Discord Logo" className="h-5 sm:h-6" /> Discord</span></a>
                                <a href="https://devnet.pocketdefi.io" className="h-full p-4 bg-[#12152B] hover:bg-[#1d2145] rounded-full text-white font-bold w-1/2 text-l sm:text-xl leading-none text-center">DEVNET Available Now!</a>
                            </div>
                        </div>

                        <div className="sm:w-[50vw]">
                            <img className="rounded-2xl drop-shadow-xl" loading="lazy" src={preview} alt="Preview" />
                        </div>
                    </div>
                    <div className="absolute bottom-5 w-full flex justify-center"><img className="self-center" alt="Scroll Down Icon" src={scroll} /></div>
                </ParallaxSection>
                <ParallaxSection className="h-screen snap-start snap-always" bgImage={bk2}>
                    <div className="flex flex-col h-screen items-center justify-center gap-y-8 p-4">
                        <div className="sm:w-[50vw] flex flex-col items-center justify-center gap-y-6">
                            <h2 className="text-white textShadow text-5xl font-bold font-sans text-center">Create Pocket Portfolios</h2>
                            <h3 className="text-white drop-shadow-md text-xl text-center sm:w-3/4">Establish a budget, pick your cryptocurrencies, and choose the strategy or theme that best fits your investment preferences and risk tolerances for your index fund portfolio.</h3>
                        </div>

                        <div className="sm:w-[50vw]">
                            <img className="rounded-2xl drop-shadow-xl" loading="lazy" src={preview2} alt="Preview" />
                        </div>
                    </div>
                </ParallaxSection>
                <ParallaxSection className="h-screen snap-start snap-always" bgImage={bk3}>
                    <div className="flex flex-col h-screen items-center justify-center gap-y-8 p-4">
                        <div className="sm:w-[60vw] flex flex-col items-center justify-center gap-y-6">
                            <h2 className="text-white textShadow text-5xl font-bold font-sans text-center">Invest in Curated Pockets</h2>
                            <h3 className="text-white drop-shadow-md text-xl text-center sm:w-3/4">As simple as few clicks, invest in a portfolio of some of the largest market capped crypto currencies in the world and sell it with ease without having to wait.</h3>
                        </div>

                        <div className="sm:w-[50vw]">
                            <img className="rounded-2xl drop-shadow-xl" loading="lazy" src={preview3} alt="Preview" />
                        </div>
                    </div>
                </ParallaxSection>
                <ParallaxSection className="h-screen snap-start snap-always" bgImage={bk2}>
                    <div className="flex flex-col h-screen items-center justify-center gap-y-8 p-4">
                        <div className="sm:w-[50vw] flex flex-col items-center justify-center gap-y-6">
                            <h2 className="text-white textShadow text-5xl font-bold font-sans text-center">Creators Earn 50% of All Platform Fees</h2>
                            <h3 className="text-white drop-shadow-md text-xl text-center sm:w-3/4">Creators receive 50% of Pocket platform fees when their pocket is copied by other users if they are NFT holders. Non-holders earn 25% of all platform fees.</h3>
                        </div>

                        <div className="sm:w-[50vw]">
                            <img className="rounded-2xl drop-shadow-xl" loading="lazy" src={preview4} alt="Preview" />
                        </div>
                    </div>
                </ParallaxSection>
                {/* <ParallaxSection className="h-screen snap-start snap-always" bgImage={bk3}>
                    <div className="flex flex-col h-screen items-center justify-center gap-y-8 p-4">
                        <div className="sm:w-[50vw] flex flex-col items-center justify-center gap-y-6">
                            <h2 className="text-white textShadow text-5xl font-bold font-sans text-center">Explore a Vast Amount of Investment Strategies</h2>
                            <h3 className="text-white drop-shadow-md text-xl text-center sm:w-3/4">The explore page is a great place for users to get exposure to new pockets.</h3>
                        </div>

                        <div className="sm:w-[50vw]">
                            <img className="rounded-2xl drop-shadow-xl" loading="lazy" src={preview5} alt="Preview" />
                        </div>
                    </div>
                </ParallaxSection> */}

            </div>
        </div>
    );
}
