// src/components/ParallaxSection.js
import React from 'react';

const ParallaxSection = ({ bgImage, children, className }) => {
  return (
    <section 
       className="relative bg-cover bg-center"
       style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div className={className}>{/* Adjust padding as needed */}
        {children}
      </div>
    </section>
  );
};

export default ParallaxSection;
